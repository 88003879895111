<template>
  <div class="application">
    <!-- 主体 -->
    <div class="mainbody">
      <div class="mainbody-title">
        <p>请选择需要注册的类型</p>
      </div>
      <div class="mainbody-content">
        <div class="c-right">
          <p class="c-title">卖家</p>
          <router-link to="/perfectinfo" class="c-space">
            <div class="content-left">
              <p class="content-left-text">
                <span><a-icon type="user" /></span>&nbsp;
                <span>个人/个体户</span>
              </p>
              <p class="left-text1">
                <span>个人卖家：委托我方代办个体工商户进行合法交易 </span>
              </p>
              <p class="left-text2">
                <span>个体户：已注册小规模纳税人，委托我方代账管理 </span>
              </p>
            </div>
          </router-link>

          <router-link to="/perfectinfo" class="c-space" v-if="false">
            <div class="content-left">
              <p class="content-left-text">
                <a-icon type="safety" />&nbsp;
                <span>个体户</span>
              </p>
              <p class="left-text1">
                <span>已注册的小规模个体户，需要委托我方代账等管理的 </span>
              </p>
            </div>
          </router-link>
        </div>
        
        <div class="c-left">
          <p class="c-title">买家</p>
          <router-link to="/buyer" class="c-space">
            <div class="content-right">
              <p class="content-right-text">
                <span><a-icon type="bank" /></span>&nbsp;
                <span>企业</span>
              </p>
              <p class="right-text1">
                <span>已经拥有营业执照的用户，进行购买操作</span>
              </p>
            </div>
          </router-link>
        </div>
        
      </div>
      <!-- <div class="bottom-text">
        <div class="bottom-left">
          <p>
            合规交易的特殊性，目前只开放个人入驻，然后委托平台办理工商注册，注册成功后，开立对应的个体户店铺
          </p>
        </div>
        <div class="bottom-right">
          <p>
            合规交易的特殊性，目前只开放企业入驻，不支持自然人和个体户申请买家
          </p>
        </div>
      </div> -->
      <div align="center" class="btm">
        <div class="btm-img">
          <img class="img1" src="@/assets/images/static/qr/u21017.png" alt="">
          <div class="img2">
            <img src="@/assets/images/wx-code.png" alt="">
            <p>扫一扫咨询了解</p>
          </div>
          <img class="img3" src="@/assets/images/static/qr/u21019.png" alt="">
        </div>
      </div>
    </div>


  </div>
</template>

<script>

export default {
  components: {
  },
};
</script>
<style lang="less" scoped>
.application {
  margin-bottom: 100px; 
   max-width: 1440px;
   min-width: 990px; @media screen and (max-width: 1440px){
   padding: 0 15px;
   }
  margin: 20px auto;
  .mainbody {
    user-select: none;
    width: 100%;
    padding-bottom: 82px;
    background-color: rgba(255, 255, 255, 1);
    .mainbody-title {
      width: 100%;
      height: 37px;
      font-size: 35px;
      color: #202020;
      font-family: "微软雅黑 Bold", "微软雅黑 Regular", "微软雅黑", sans-serif;
      font-weight: 700;
      padding: 115px 0 90px 0;
      text-align: center;
    }
    .mainbody-content {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        height: 110%;
        left: 50%;
        top: 50%;
        width: 1px;
        background: #aaaaaa;
        transform: translate(50%, -50%);
      }
    }
    .mainbody-content,
    .bottom-text {
      display: flex;
      justify-content: center;
      margin: 0 auto;
      .content-left,
      .content-right {
        cursor: pointer;
        width: 417px;
        height: 217px;
        background-color: rgba(76, 125, 255, 1);
        border-radius: 10px;
        .content-left-text,
        .content-right-text {
          padding: 55px 0 0 0;
          font-size: 36px;
          color: #fdfcfc;
          text-align: center;
        }
        .left-text1,
        .right-text1 {
          padding: 55px 0 0 0;
          text-align: center;
          font-size: 14px;
          color: #e5e5e5;
        }
        .left-text2 {
          margin-top: 16px;
          text-align: center;
          font-size: 14px;
          color: #e5e5e5;
          // padding-left: 14px;
        }
      }
    }
  }
}
.c-right {
  margin-right: 64px;
}
.c-left {
  margin-left: 64px;
}
.c-title {
  font-family: 'Arial Negreta', 'Arial Normal', 'Arial', sans-serif;
  font-weight: 700;
  font-size: 32px;
  text-align: center;
  color: rgb(102, 102, 102);
}
.c-space {
  margin-top: 36px;
  display: inherit;
}
.btm {
  margin-top: 100px;
}
.btm-img {
  text-align: initial;
  position: relative;
  display: inline-block;
  margin-right: 142px;
  .img2 {
    position: absolute;
    background: #fff;
    width: 280px;
    right: -36px;
    top: 14px;
    padding: 10px 20px;
    img {
      width: 107px;
      height: 107px;
    }
    p {
      margin-top: 6px;
      width: 107px;
      font-family: 'Arial Negreta', 'Arial Normal', 'Arial', sans-serif;
      font-weight: 700;
      font-style: normal;
      color: #BFBF00;
      text-align: center;
    }
  }
  .img3 {
    position: absolute;
    right: -142px;
    top: 24px;
  }
}
</style>